import discoverBenefitsImg from '@/client/assets/images/discoverBenefitsImg.png'
import grayTick from '@/client/assets/images/grayTick.svg'
import Ecclipse3d from '@/client/assets/images/3dEcllipse_4x.png'

const DiscoverBenefits = () => {

  const benefitsArr = [
    'Industry-specific keywords to increase your visibility',
    'Drag-and-reorganize sections for easy customization',
    'See real-time preview to make changes.',
    'Save your progress and return to it later.',
    'Strong data encryption and privacy measures to protect information.',
    'A variety of professionally designed resume templates',
    'A variety of professionally designed resume templates',
    'A variety of professionally designed resume templates',
    'A variety of professionally designed resume templates',
  ]

  return (
    <section id="benefits" className="relative bg-[#121211]">
      <div className="mx-auto w-full px-[1rem] xs:px-[2rem] lg:px-[4rem] xl:px-[9rem] py-[5%]">
        <img src={Ecclipse3d} alt="3dEcllipse" className='absolute -top-48 -left-40 right-0 bottom-0' style={{width: '25%'}} />
        <div className={`w-[100%] lg:w-[55%] text-center flex flex-col gap-5`} style={{ margin: '0 auto' }}>
          <h1 className='capitalize text-[1.5rem] xs:text-[2rem] lg:text-5xl font-semibold text-[#FFFFFF]'>{`Discover our benefits`}</h1>
          <p className='text-[15px] xs:text-[1rem] lg:text-lg text-[#FFFFFF]'>{`Explore the advantages of our platform, including intuitive design, diverse templates, and easy sharing options to create your perfect CV effortlessly.`}</p>
        </div>
        <div className={`mb-12 mt-8 lg:mt-20 flex flex-col xs:flex-row justify-between items-center`}>
          <img src={discoverBenefitsImg} alt="" className='max-w-[60%] xs:max-w-[40%] lg:max-w-[100%]' />
          <div className={`flex flex-col gap-[0.7rem] lg:gap-7 w-[96%] xs:w-[52%] lg:w-2/5 text-[#edebeb] mt-[20px] xs:mt-0`}>
            {benefitsArr?.map((text, key) =>
              <div key={key} className={`flex items-start gap-[0.9rem] xs:gap-[0.7rem] lg:gap-4`}>
                <img src={grayTick} alt="grayTick" height={20} width={20} />
                <p className='text-[1rem] lg:text-xl !leading-[1.5rem] [#edebeb]'>{text}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default DiscoverBenefits